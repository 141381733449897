var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "timedVoiceDistribution-page" },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, stripe: "", height: "90%" } },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "companyNames",
              label: "公司",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.companyNames &&
                            scope.row.companyNames.length
                            ? scope.row.companyNames.join(",")
                            : ""
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "cph", label: "车辆", "show-overflow-tooltip": "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          scope.row.cph && scope.row.cph.length
                            ? scope.row.cph.join(",")
                            : ""
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { prop: "sign", label: "消息类型" } }),
          _c("el-table-column", {
            attrs: { prop: "userName", label: "下发人" }
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "创建时间", width: "180" }
          }),
          _c("el-table-column", {
            attrs: { prop: "beginTime", label: "下发开始日期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(scope.row.beginTime.substring(0, 10)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "endTime", label: "下发结束日期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(scope.row.endTime.substring(0, 10)) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "sendTime", label: "下发时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.sendTime.substring(10)) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "message",
              label: "消息内容",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        style: {
                          color: scope.row.status == 1 ? "#f74242" : ""
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.status == 1
                                ? "禁用"
                                : scope.row.status == 0
                                ? "启用"
                                : ""
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type:
                            scope.row.status == 1
                              ? "success"
                              : scope.row.status == 0
                              ? "danger"
                              : "",
                          size: "small"
                        },
                        on: {
                          click: function($event) {
                            return _vm.editDataFun(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.status == 1
                                ? "启用"
                                : scope.row.status == 0
                                ? "禁用"
                                : ""
                            )
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.total,
          "page-size": _vm.form.pageSize,
          "current-page": _vm.form.currentPage
        },
        on: {
          "size-change": _vm.onSizeChange,
          "current-change": _vm.onCurrentChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }