<template>
  <!-- 定时下发语音 -->
  <div class="timedVoiceDistribution-page">
    <el-table :data="tableData" stripe height="90%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="companyNames" label="公司" show-overflow-tooltip>
        <template slot-scope="scope">
          {{
            scope.row.companyNames && scope.row.companyNames.length
              ? scope.row.companyNames.join(",")
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="cph" label="车辆" show-overflow-tooltip>
        <template slot-scope="scope">
          {{
            scope.row.cph && scope.row.cph.length ? scope.row.cph.join(",") : ""
          }}
        </template>
      </el-table-column>
      <el-table-column prop="sign" label="消息类型"></el-table-column>
      <el-table-column prop="userName" label="下发人"></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column prop="beginTime" label="下发开始日期">
        <template slot-scope="scope">
          {{ scope.row.beginTime.substring(0, 10) }}
        </template>
      </el-table-column>
      <el-table-column prop="endTime" label="下发结束日期">
        <template slot-scope="scope">
          {{ scope.row.endTime.substring(0, 10) }}
        </template>
      </el-table-column>
      <el-table-column prop="sendTime" label="下发时间">
        <template slot-scope="scope">
          {{ scope.row.sendTime.substring(10) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="message"
        label="消息内容"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span
            :style="{
              color: scope.row.status == 1 ? '#f74242' : ''
            }"
          >
            {{
              scope.row.status == 1
                ? "禁用"
                : scope.row.status == 0
                ? "启用"
                : ""
            }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button
            :type="
              scope.row.status == 1
                ? 'success'
                : scope.row.status == 0
                ? 'danger'
                : ''
            "
            size="small"
            @click="editDataFun(scope.row)"
          >
            {{
              scope.row.status == 1
                ? "启用"
                : scope.row.status == 0
                ? "禁用"
                : ""
            }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :page-size="form.pageSize"
      :current-page="form.currentPage"
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
import {
  queryMessageTaskPageAPI,
  updateMessageTaskAPI
} from "@/api/lib/refreshAlarm.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      total: 0,
      form: { pageSize: 10, currentPage: 1 },
      tableData: []
    };
  },
  filters: {},
  computed: {},
  watch: {},
  created() {
    this.getQueryMessageTaskPage();
  },
  mounted() {},
  methods: {
    editDataFun(row) {
      let params = JSON.parse(JSON.stringify(row));
      params.status = params.status == 0 ? 1 : 0;
      updateMessageTaskAPI(params).then(res => {
        if (res.code === 1000) {
          this.getQueryMessageTaskPage();
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getQueryMessageTaskPage() {
      queryMessageTaskPageAPI(this.form).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data.list || [];
          this.total = res.data.total;
        }
      });
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.getQueryMessageTaskPage();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.getQueryMessageTaskPage();
    }
  }
};
</script>

<style lang="scss" scoped>
.timedVoiceDistribution-page {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
</style>
